import Axios, {AxiosRequestConfig} from 'axios';

export default class Http {
  private backendHost = process.env.REACT_APP_API_ENDPOINT;
  public requestConfig: Partial<AxiosRequestConfig> = {};
  public prefix = '/';

  constructor(prefix: string) {
      this.prefix = prefix;
      this.requestConfig.baseURL = `${this.backendHost}/${this.prefix}`;
  }

  public async get(
      url: string,
      requestConfig: Partial<AxiosRequestConfig> = {}
  ): Promise<any> {
      return await Axios.get(url, {
          ...this.requestConfig,
          ...requestConfig,
      });
  }

  public async post(
      url: string,
      data: Record<string, unknown>,
      requestConfig: Partial<AxiosRequestConfig> = {}
  ): Promise<any> {
      return await Axios.post(
          url,
          {...data},
          {...this.requestConfig, ...requestConfig}
      );
  }

  public async put(
      url: string,
      data: Record<string, unknown>,
      requestConfig: Partial<AxiosRequestConfig> = {}
  ): Promise<any> {
      return await Axios.put(url, data, {
          ...this.requestConfig,
          ...requestConfig,
      });
  }

  public async delete(
      url: string,
      requestConfig: Partial<AxiosRequestConfig> = {}
  ): Promise<any> {
      return await Axios.delete(url, {
          ...this.requestConfig,
          ...requestConfig,
      });
  }
}
