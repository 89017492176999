import React from 'react';
import {useTranslation} from 'react-i18next';

const Unavailable = (props: any) => {
    const {t} = useTranslation();
    return (
        <div
            className="flex items-center flex-col bg-objective-validation h-screen"
            style={{
                backgroundImage: 'url(\'img/bg-pattern.png\')',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                overflow: 'hidden',
            }}
        >
            <img
                src="img/logos/logo-md.png"
                className="mt-60"/>
            <div
                className="mt-4 font-josefin text-white font-bold text-center text-8xl tracking-wide"
            >
                {t('oops')}
            </div>
            <div className="font-josefin col-10 text-center text-white font-bold text-lg tracking-wide">
                {t('unavailableText')}
            </div>
        </div>
    );
};

export default Unavailable;
