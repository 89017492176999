import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import i18n from 'i18next';

import Routes from './Routes';

import HttpStorageI18n from './services/storage/Language';
import {languagesList, languageDefault} from './utils/Language';

import './DefaultStyles';

class App extends Component<WithTranslation> {
    componentDidMount() {
        if (!HttpStorageI18n.getItem('language')) {
            let userLang = languageDefault.acronym;
            const navigatorLanguage = navigator.language.slice(0, 2);
            const objFindLang = languagesList.find(
                (item: any) => item.acronym === navigatorLanguage
            );
            if (objFindLang) userLang = objFindLang.acronym;

            HttpStorageI18n.saveLanguage(userLang);
            i18n.changeLanguage(userLang, () => {
                window.location.reload();
            });
        }
    }

    render() {
        const basename = '/';

        return (
            <BrowserRouter basename={basename}>
                <Routes />
            </BrowserRouter>
        );
    }
}

export default withTranslation()(App);
