import Axios from 'axios';

import StorageAuth from './../services/storage/Auth';
import {objectKeysToCamelCase} from 'utils/utils';

const SAuth = new StorageAuth();

export default () => {
    Axios.interceptors.request.use(async (config) => {
        const token = SAuth.getSession().accessToken;
        if (token) {
            let t = token;
            if (t.startsWith('Bearer') == false) {
                t = `Bearer ${token}`;
            }
            config.headers.Authorization = `${t}`;
        }

        return config;
    });

    /**
   * Las respuestas nos llegan como snake_case;
   */
    Axios.interceptors.response.use(
        (response) => {
            response.data = objectKeysToCamelCase(response.data);
            return response;
        },
        (error) => {
            error.response.data = objectKeysToCamelCase(error.response.data);
            return Promise.reject(error);
        }
    );

    // Axios.interceptors.response.use(undefined, async (error) => {
    // 	if (error?.response?.data && error.response.data.statusCode == 403) {
    // 		const Session = SAuth.getSession();
    // 		if (!Session.rememberMe || !Session.refreshToken) {
    // 			SAuth.removeSession();
    // 			Notification.display(
    // 				"warning",
    // 				`Session expired. Refresh page and login again.`
    // 			);

    // 			throw new Axios.Cancel();
    // 		}

    // 		let refreshedSession;
    // 		try {
    // 			refreshedSession = await new Auth().refreshToken(
    // 				Session.refreshToken
    // 			);
    // 			SAuth.saveSession({
    // 				accessToken: refreshedSession.accessToken,
    // 				refreshToken: Session.refreshToken,
    // 				rememberMe: Session.rememberMe,
    // 			});
    // 		} catch (err) {
    // 			SAuth.removeSession();
    // 			Notification.display(
    // 				"error",
    // 				`Could not update token. Please log in again.`
    // 			);
    // 			window.location.href = AppPaths.login;
    // 			throw new Axios.Cancel();
    // 		}
    // 		return Axios.request(error.config);
    // 	}

    // 	throw error;
    // });
};
