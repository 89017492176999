import Axios from 'axios';
import Http from './Http';

type TUserRequest = {
    'id'?: string
    'avatar_path'?: string,
    'email'?: string,
    'birth_date'?: string,
    'birth_year'?: string,
    'password'?: string,
    'username'?: string,
    'gender'?: string,
    'from_address'?: string,
    'live_address'?: string,
    'email_notification'?: boolean,
    'push_notification'?: boolean,
    'name'?: string,
    'web'?: string,
    'code'?: string,
}

export default class User extends Http {
    constructor() {
        super('');
    }

    async me(): Promise<any> {
        let result = {data: ''};

        try {
            result = await Axios.get(`/me`, this.requestConfig);
        }
        catch (err) {
            console.log(err);
        }
        return result;
    }

    async create(data: TUserRequest) {
        let result = {data: {accessToken: ''}};

        try {
            result = await this.post(`/users`, data, this.requestConfig);
        }
        catch (err) {
            console.log(err);
        }
        return result;
    }

    async update(data: TUserRequest) {
        let result = {data: ''};

        try {
            result = await this.put(`/me`, data, this.requestConfig);
        }
        catch (err) {
            console.log(err);
        }
        return result;
    }
}
