import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useInterval from '../../../utils/useInterval';

interface ITimedQuestionPrompt {
    question?: string,
    maxDeltaTime?: number;
    onClickYes: (deltaTime: number) => void;
    onClickNo: (deltaTime: number) => void;
    onTimeOut: (deltaTime: number) => void;
}
const TimedQuestionPrompt = (props: ITimedQuestionPrompt) => {
    const {
        question,
        maxDeltaTime = 5 * 100,
        onClickYes,
        onClickNo,
        onTimeOut,
    } = props;
    const {t} = useTranslation();
    const [timeLeft, setTimeLeft] = useState<number>(maxDeltaTime);
    const [timeOut, setTimeOut] = useState<boolean>(false);

    useInterval(async () => {
        if (timeOut) return;
        if (!timeOut && timeLeft <= 0) {
            setTimeOut(true);
            return;
        }
        setTimeLeft(timeLeft - 1);
    }, 10);

    useEffect(() => {}, [question]);

    useEffect(() => {
        if (timeOut) onTimeOut(maxDeltaTime - timeLeft);
    }, [timeOut]);

    return (
        <div
            className={`absolute font-montserrat z-50 top-0 bottom-0 right-0 left-0 w-screen h-screen flex flex-col items-center text-white bg-black`}
        >
            <div className="h-1/6"></div>
            <span className="text-4xl text-gray-400" >{Math.floor(timeLeft / 100).toFixed(0).padStart(2, '0')}:{String(timeLeft % 100).padStart(2, '0')}</span>
            <div className="h-10"></div>
            <div className="flex flex-col text-2xl md:text-4xl font-semibold items-center justify-center w-full md:w-1/3">
                <span className="text-center md:mb-4">{t('hasThisVideo')}</span>
                <span className="text-center" style={{color: 'rgba(255, 174, 0, 1)'}}>{question}</span>
                <div className="h-20 md:h-40"></div>
                <div style={{fontSize: '100px', lineHeight: '100px'}} className="flex flex-row w-full justify-between px-5 md:px-0">
                    <button onClick={() => {
                        onClickYes(maxDeltaTime - timeLeft);
                    }} className="md:text-right font-semibold uppercase">{t('yes')}</button>
                    <button onClick={() => {
                        onClickNo(maxDeltaTime - timeLeft);
                    }} className="md:text-left font-semibold uppercase">{t('no')}</button>
                </div>
            </div>
        </div>
    );
};

export default TimedQuestionPrompt;
