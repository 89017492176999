import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';
import * as faceapi from 'face-api.js';

import AppPaths from '../../permissions/AppPaths';
import TCampaign from '../../types/TCampaign';
import DrawOptionsFaceApi from '../../types/TDrawOptionsFaceApi';
import CampaignService from '../../services/http/Campaign';
import UnsupportedVideoMimeType from '../../services/exception/UnsupportedVideoMimeType';
import useInterval from '../../utils/useInterval';
import Notification from '../../components/Common/Notification/Notification';
import TimedQuestionPrompt from '../../components/Elements/QuestionPrompt/TimedQuestionPrompt';

const DRAW_CONFIGURATION: DrawOptionsFaceApi = {
    drawLines: false,
    drawPoints: true,
    lineWidth: 2,
    pointSize: 1,
    lineColor: '#FE406C',
    pointColor: '#ffff',
};

const TIME_ANIMATION_RECOGNITION = 2000; // if change this value, change the value in the animation off css file: src/styles/app.css in class .scanline
const INTERVAL_FACEAPI_RECOGNITION = 200;
const TIME_RESET_RECOGNITION_WITH_FACEAPI = 2000;
const Campaign = (props: any) => {
    const {t} = useTranslation();
    const campaignCode = props.match.params.code;

    const [campaign, setCampaign] = useState<Partial<TCampaign>>();
    const [mediaEnd, setMediaEnd] = useState<boolean>(false);
    const [reactionReady, setReactionReady] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [reactionStream, setReactionStream] = useState<MediaStream | null>(null);
    const [reactionMediaRecorder, setReactionMediaRecorder] = useState<MediaRecorder | null>(null);
    const [reactionCaptureEnded, setReactionCaptureEnded] = useState<boolean>(false);
    const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
    const [videoReaction, setVideoReaction] = useState<Blob|undefined>(undefined);
    const [timeToReaction, setTimeoutToReaction] = useState<boolean>(false);

    const reactionRef = useRef<HTMLVideoElement>(null!);
    const mediaRef = useRef<HTMLVideoElement>(null!);
    const playRef = useRef<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement>(null!);
    const recognitionFace = useRef<boolean>(false);

    useEffect(() => {
        const loadModels = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri(window.location.origin + '/models/');
            await faceapi.nets.faceLandmark68Net.loadFromUri('https://cdn.jsdelivr.net/npm/@vladmandic/face-api/model/');
        };
        loadModels();
    }, []);

    useEffect(() => {
        const loadCampaign = async () => {
            try {
                setCampaign(
                    await new CampaignService().get(campaignCode)
                );
            }
            catch (e) {
                props.history.push(AppPaths.notFound);
            }
        };
        if (campaign === undefined) {
            loadCampaign().then();
        }
    }, [campaign]);

    const resetCanvas = () => {
        if (canvasRef && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
    };
    const drawInCanvasUsingFaceApi = (faces: faceapi.WithFaceLandmarks<{ detection: faceapi.FaceDetection }>[]) => {
        if (canvasRef && canvasRef.current && faces.length > 0) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }

            const resizedDetections = faceapi.resizeResults(faces, { width: canvas.width, height: canvas.height });

            const drawOptions = new faceapi.draw.DrawFaceLandmarksOptions(DRAW_CONFIGURATION);

            resizedDetections.forEach((detection) => {
                const drawLandmarksInstance = new faceapi.draw.DrawFaceLandmarks(detection.landmarks, drawOptions);
                drawLandmarksInstance.draw(canvas);
            });
        }
    };

    const detectAllFacesWithLandmarks = async () => {
        return await faceapi.detectAllFaces(reactionRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
    };

    const realTimeRecognitionFaceToSmallScreens = () => {
        setTimeout(() => {
            recognitionFace.current = true;
            resetCanvas();
        }, TIME_ANIMATION_RECOGNITION);
    };

    const realTimeRecognitionFaceToAllScreens = () => {
        setTimeout(() => {
            let isIntervalStopped = false;
            const intervalId = setInterval(async () => {
                if (isIntervalStopped) return;
                const facesWithLandmarks = await detectAllFacesWithLandmarks();
                if (isIntervalStopped) return;
                drawInCanvasUsingFaceApi(facesWithLandmarks);
            }, INTERVAL_FACEAPI_RECOGNITION);
            setTimeout(() => {
                isIntervalStopped = true;
                clearInterval(intervalId);
                recognitionFace.current = true;
                resetCanvas();
            }, TIME_RESET_RECOGNITION_WITH_FACEAPI);
        }, TIME_ANIMATION_RECOGNITION);
    };

    const realTimeScannerWithFaceApi = async () => {
        const isSM = window.innerWidth <= 640;
        (isSM) ?
            realTimeRecognitionFaceToSmallScreens() :
            realTimeRecognitionFaceToAllScreens();
    };

    const detectFaces = async () => {
        try {
            if (reactionStream === null && reactionRef.current && !reactionRef.current.srcObject) {
                const stream = await navigator.mediaDevices.getUserMedia({audio: false, video: true});
                setReactionStream(stream);
                reactionRef.current.srcObject = stream;
            }
            else if (reactionStream !== null && reactionRef.current) {
                const faces = await detectAllFacesWithLandmarks();
                realTimeScannerWithFaceApi();
                return faces.length > 0;
            }
        }
        catch (e) {
            // Notification.display('error', e.message);
            const error = e as Error;
            console.log('error', error.message);
        }
        return false;
    };

    useInterval(async () => {
        const wasFaceDetected = await detectFaces();
        setReactionReady(wasFaceDetected);
    }, !reactionReady ? 500 : null);

    const getMimeType = (): string => {
        const types = [
            'video/mp4;codecs=h264',
            'video/webm;codecs=h264',
            'video/quicktime;codecs=h264',
            'video/mp4',
            'video/webm',
            'video/quicktime',
        ];
        for (const type of types) {
            if (MediaRecorder.isTypeSupported(type)) {
                return type;
            }
        }
        throw new UnsupportedVideoMimeType();
    };

    const uploadAnswer = async (response: boolean|null, deltaTime: number) => {
        try {
            if (!questionAnswered) setQuestionAnswered(true);
            if (reactionCaptureEnded) {
                await new CampaignService().sendReaction(
                    campaign?.id!,
                    videoReaction!,
                    mime.getExtension(getMimeType()) ?? 'mp4',
                );
                await new CampaignService().sendResponse(
                    campaign?.id!,
                    response,
                    deltaTime * 10,
                );
                props.history.push(
                    AppPaths.thanks.replace(':code', campaignCode),
                );
            }
            else {
                setTimeout(() => uploadAnswer(response, deltaTime), 500);
            }
        }
        catch (error) {
            Notification.displayException(error);
        }
    };

    useEffect(() => {
        const sendReaction = async () => {
            try {
                await new CampaignService().sendReaction(
                    campaign?.id!,
                    videoReaction!,
                    mime.getExtension(getMimeType()) ?? 'mp4',
                );
            }
            catch (e) {
                Notification.display('error', t('userAlreadyReacted'));
            }
            finally {
                if (campaign?.question === undefined || campaign?.question === null) {
                    props.history.push(
                        AppPaths.thanks.replace(':code', campaignCode),
                    );
                }
            }
        };
        if (reactionCaptureEnded && (campaign?.question === null || campaign?.question === undefined)) {
            sendReaction().then();
        }
    }, [
        reactionCaptureEnded,
    ]);

    const recordReaction = async () => {
        if (reactionStream instanceof MediaStream) {
            const mediaRecorder = new MediaRecorder(reactionStream, {mimeType: getMimeType()});
            const chunks: Array<Blob> = [];

            mediaRecorder.ondataavailable = (blob: BlobEvent) => {
                if (blob.data.size > 0) {
                    chunks.push(blob.data);
                }
            };

            mediaRecorder.onstop = async () => {
                const _type = getMimeType();
                setVideoReaction(new Blob(chunks, {type: _type}));
                setReactionCaptureEnded(true);
            };

            mediaRecorder.start(3000);
            setReactionMediaRecorder(mediaRecorder);
        }
    };

    useEffect(() => {
        if (mediaEnd) {
            reactionMediaRecorder?.stop();
        }
    }, [mediaEnd]);

    const startReaction = () => {
        if (reactionReady && recognitionFace.current === true) {
            setTimeoutToReaction(true);
        }
        else {
            Notification.display('error', 'Wait for facial recognition to finish');
        }
    };

    return (
        timeToReaction ? (
            <div>
                {!mediaEnd ?
                    <>
                        {/* Grabbing reaction */}
                        <div>
                            <div className="flex h-screen h-full w-full bg-black justify-center items-center bg-black">
                                {reactionReady && showPlayButton ?
                                    <img
                                        onClick={() => {
                                            setShowPlayButton(false);
                                            recordReaction().then();
                                        }}
                                        src="/img/play.png"
                                        alt="play..."
                                        className="h-2/12 w-2/12 md:h-1/12 md:w-1/12"/> :
                                    null}
                                {reactionReady && !showPlayButton ?
                                    <video
                                        autoPlay
                                        playsInline
                                        src={campaign?.media}
                                        onEnded={() => {
                                            setMediaEnd(true);
                                        }}
                                        preload={'auto'}
                                        className="h-full md:w-full! object-contain"
                                        ref={mediaRef}/> :
                                    null}
                            </div>
                        </div>
                    </> :
                    questionAnswered || campaign?.question === undefined || campaign?.question === null ?
                        <>
                            {/* Uploading reaction */}
                            <div className="h-screen flex flex-col flex-row h-full w-full cover" style={{
                                backgroundImage: 'url("/img/promo_coming.jpg")',
                                backgroundSize: '100% 100%',
                            }}>
                                <span
                                    className="text-white font-josefin font-semibold mt-8 md:mt-16 ml-8 md:ml-16 col-8"
                                    style={{
                                        fontSize: 44,
                                        letterSpacing: -3.83,
                                    }}>
                                Sending your <span className="text-objective-validation">reaction…</span>
                                </span>
                                <img
                                    src="/img/drawing.gif"
                                    alt="loading..."
                                    className="fixed bottom-20 md:bottom-20 right-16 md:right-20 w-3/12 lg:w-2/12"/>
                            </div>
                        </>:
                        <>
                            {/* Question */}
                            <TimedQuestionPrompt
                                question={campaign?.question}
                                onClickNo={(deltaTime) => {
                                    uploadAnswer(false, deltaTime).then();
                                }}
                                onClickYes={(deltaTime) => {
                                    uploadAnswer(true, deltaTime).then();
                                }}
                                onTimeOut={(deltaTime) => {
                                    uploadAnswer(null, deltaTime).then();
                                }}
                            />
                        </>
                }
            </div>
        ) : (
            <div className='flex h-screen w-full bg-black justify-center items-center'>
                <div className=' flex flex-col bg-white p-5 md:p-8 cardCamera'>
                    <div className="relative flex justify-center items-center mb-8 videoCameraContainer">
                        <video
                            autoPlay
                            playsInline
                            muted
                            className="object-cover w-full h-full videoCamera"
                            preload='auto'
                            ref={reactionRef}
                            onPlay={() => playRef.current = true}
                        />
                        {playRef.current && <div className="scanline absolute top-0 left-0 w-full h-full pointer-events-none"></div>}
                        <canvas
                            ref={canvasRef}
                            className="absolute top-0 left-0 w-full h-full pointer-events-none"
                        />
                    </div>

                    <p className='font-montserrat text-xl font-medium tracking-071pt text-darkBlueText mb-4 firtParagraph'>
                        {t('reactionCardParagraph1')} {' '}
                        <span className='font-montserrat text-xl font-bold tracking-071pt text-darkBlueText'>
                            {t('reactionCardSpanParagraph1')}
                        </span>
                    </p>

                    <p className='font-montserrat text-sm font-medium text-midnightBlueText mb-8 secondParagraph'>
                        ({t('reactionCardParagraph2')})</p>
                    <button
                        className="font-montserrat font-semibold text-base bg-startReactionButton uppercase rounded-3xl text-white self-center"
                        style={{width: '220px', height: '44px'}}
                        onClick={startReaction}
                    >
                        {t('reactionCardButton')}
                    </button>
                </div>
            </div>

        )
    );
};

export default Campaign;
