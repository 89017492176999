import Axios from 'axios';
import {handleError} from './utils';
import {languageDefault} from '../../utils/Language';
import Http from './Http';

type TLogin = {
	accessToken: string;
	refreshToken: string;
	userLanguage?: string;
};

type TRefreshToken = {
	accessToken: string;
};

export default class Auth extends Http {
    constructor() {
        super('');
    }

    async loginWithPassword(email: string, password: string): Promise<TLogin> {
        return this.login(email, password, 'password');
    }

    async loginWithSecurityCode(
        email: string,
        securityCode: string
    ): Promise<TLogin> {
        return this.login(email, securityCode, 'security_code');
    }

    private async login(
        email: string,
        value: string,
        securityType: 'password' | 'security_code'
    ) {
        let result: any;
        try {
            const req: any = {email};
            req[`${securityType}`] = value;
            result = await Axios.post(`/tokens`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
        const userLanguage =
			result.data.userLanguage || languageDefault.acronym;
        return {
            ...result.data,
            userLanguage,
        };
    }

    async refreshToken(refreshTokenString: string): Promise<TRefreshToken> {
        let result: any;
        try {
            result = await Axios.post(
                `/refresh`,
                {refreshToken: refreshTokenString},
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }

        const {accessToken} = result.data;

        return {
            accessToken,
        };
    }

    async resetPassword(email: string) {
        try {
            return await Axios.delete(`/users/password`, {
                ...this.requestConfig,
                data: {email: email},
            });
        }
        catch (err) {
            handleError(err);
        }
    }
}
