/* eslint-disable */ 
import TUser from 'types/TUser';
import JwtDecode from 'jwt-decode';

export enum ESessionKeys {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  RememberMe = 'rememberMe',
  LoginAt = 'loginAt',
}

export type TSession = {
  accessToken: string | null;
  refreshToken?: string | null;
  rememberMe?: boolean | null;
  loginAt?: Date | null;
};

const KEYS: ESessionKeys[] = [
    ESessionKeys.AccessToken,
    ESessionKeys.RefreshToken,
    ESessionKeys.RememberMe,
];

export class Auth {
    public saveSession(session: TSession): void {
        this.setItem(ESessionKeys.AccessToken, session.accessToken);
        this.setItem(ESessionKeys.RefreshToken, String(session.refreshToken));
        this.setItem(ESessionKeys.LoginAt, new Date().toString());
        this.setItem(ESessionKeys.RememberMe, String(session.rememberMe));
    }

    public removeSession(): void {
        KEYS.forEach((key: ESessionKeys) => {
            this.removeItem(key);
        });
    }

    public getSession(): TSession {
        let loginAt: any = this.getItem(ESessionKeys.LoginAt);
        if (loginAt) {
            loginAt = new Date(loginAt);
        }

        return {
            accessToken: this.getItem(ESessionKeys.AccessToken),
            refreshToken: this.getItem(ESessionKeys.RefreshToken),
            loginAt: loginAt,
            rememberMe: this.getItem(ESessionKeys.RememberMe) === 'true',
        };
    }

    public decodeAccessToken(token: string): TUser {
        return this.decodeJwt(token);
    }

    public decodeJwt(token: string): any {
        return JwtDecode(token);
    }

    public getSessionUser(): TUser | null {
        const accessToken = this.getItem(ESessionKeys.AccessToken);
        let ret = null;
        if (accessToken) {
            ret = this.decodeAccessToken(accessToken);
        }
        return ret;
    }

    public isTokenLapsed(session: TSession): boolean {
        return !this.isTokenAlive(session);
    }

    public isTokenAlive(session: TSession): boolean {
        const user = this.getSessionUser();

        let ret = false;
        if (user) {
            if (session.accessToken && session.refreshToken && session.loginAt) {
                const now = new Date();
                const lapseTime = session.loginAt;
                const exp = this.decodeJwt(`${this.getAccessToken()}`)?.exp;

                if (session.loginAt && exp && lapseTime) {
                    lapseTime.setSeconds(lapseTime.getSeconds() + exp);
                    if (now < lapseTime || session.rememberMe) {
                        ret = true;
                    }
                }
            }
        }
        return ret;
    }

    private setItem(key: ESessionKeys, value: string | null) {
        localStorage.setItem(`auth.${key}`, String(value));
    }

    public getItem(key: ESessionKeys): string | null {
        return localStorage.getItem(`auth.${key}`);
    }

    private removeItem(key: ESessionKeys) {
        localStorage.removeItem(`auth.${key}`);
    }

    public getAccessToken() {
        return this.getItem(ESessionKeys.AccessToken);
    }
}

export default Auth;
