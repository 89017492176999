import React, {lazy} from 'react';
import EUserRol from 'types/EUserRol';
import Campaign from '../pages/Campaign/Campaign';
import NotFound from '../pages/NotFound/NotFound';
import Thanks from '../pages/Thanks/Thanks';
import Unavailable from '../pages/Unavailable/Unavailable';

interface IRoute {
    path: string;
    component: React.Component | any;
    roles: EUserRol[];
}

const Landing = lazy(() => import('pages/Landing/Landing'));

export const PATHS = {
    landing: '/campaign/:code',
    campaign: '/campaign/:code/react',
    notFound: '/404',
    thanks: '/campaign/:code/thanks',
    unavailable: '/tooLate',
};
export default PATHS;

export const AUTH_ROUTES: IRoute[] = [
    {
        path: PATHS.campaign,
        component: Campaign,
        roles: [EUserRol.USER],
    },
    {
        path: PATHS.landing,
        component: Landing,
        roles: [EUserRol.USER],
    },
    {
        path: PATHS.notFound,
        component: NotFound,
        roles: [EUserRol.USER],
    },
    {
        path: PATHS.thanks,
        component: Thanks,
        roles: [EUserRol.USER],
    },
    {
        path: PATHS.unavailable,
        component: Unavailable,
        roles: [EUserRol.USER],
    },
];

export const ROUTES: IRoute[] = [
    {
        path: PATHS.landing,
        component: Landing,
        roles: [],
    },
    {
        path: PATHS.notFound,
        component: NotFound,
        roles: [],
    },
    {
        path: PATHS.unavailable,
        component: Unavailable,
        roles: [],
    },
];

export function getRoutesByRole(roles: EUserRol[]): IRoute[] {
    const ret: IRoute[] = [];

    AUTH_ROUTES.forEach((route: IRoute) => {
        let i = 0;
        let hasRole = false;
        while (hasRole == false && i < roles.length) {
            hasRole = route.roles.indexOf(roles[i]) !== -1;
            i++;
        }
        if (hasRole) {
            ret.push(route);
        }
    });

    return ret;
}

export function getUnauthorizedRoutes(): IRoute[] {
    return ROUTES;
}
