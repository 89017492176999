import React, {useState} from 'react';
import {NotificationContainer} from '../Common/Notification/Notification';

import ModalProvider, {ModalProviderData} from 'providers/modalProvider';
import ModalTest from 'components/Modals/ModalTest';
import Sideright from './Sideright/Sideright';
import SiderightProvider from 'providers/siderightProvider';

import './Base.scss';
import {APP_MODALS} from '../../constants';
import ModalForgotPassword from '../Modals/ModalForgotPassword';

const Base = ({menu, children}: any) => {
    const [showModal, setShowModal] = useState({modalName: ''});
    const [sideright, setSideright] = useState(false);

    const getModalComponent = (ctx: string | ModalProviderData) => {
        const defaultModalProps = {
            showModal: '',
            setShowModal: setShowModal,
            data: null,
        };

        let modalName = '';
        if (typeof ctx == 'string') {
            modalName = ctx;
        }
        else if (typeof ctx == 'object') {
            modalName = ctx.modalName;
            defaultModalProps.data = ctx.data;
        }
        defaultModalProps.showModal = modalName;
        let ret = null;
        switch (modalName) {
        case 'test':
            ret = <ModalTest {...defaultModalProps} />;
            break;
        case APP_MODALS.modalForgotPassword:
            ret = <ModalForgotPassword {...defaultModalProps} />;
            break;
        }
        return ret;
    };

    return (
        <div className="wrapper">
            <NotificationContainer />
            <ModalProvider.Provider value={[showModal, setShowModal]}>
                <SiderightProvider.Provider value={[sideright, setSideright]}>
                    {children}
                    <SiderightProvider.Consumer>
                        {(value) => (value ? <Sideright {...value} /> : '')}
                    </SiderightProvider.Consumer>

                    <ModalProvider.Consumer>
                        {(value) => getModalComponent(value[0])}
                    </ModalProvider.Consumer>
                </SiderightProvider.Provider>
            </ModalProvider.Provider>
            {/* <Footer /> */}
        </div>
    );
};

export default Base;
