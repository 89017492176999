import React, { useEffect } from 'react';
import mergeClasses from 'utils/TailwindMergeClasses';

export interface IInputProps {
  children?: any;
  inputClassName?: string;
  wrapperClassName?: string;
  placeholder?: string;
  label?: string;
  labelClassName?: string;
  type?: 'text' | 'password' | 'file' | 'date' | string;
  [key: string]: any;
  name: string;
  valid?: boolean;
  value?: any;
  invalidFeedback?: string;
  defaultValue?: string | any,
}

const Input = (props: IInputProps) => {
    const {
        name = '',
        onChange,
        placeholder = ' ',
        label,
        labelClassName = '',
        type = 'text',
        inputClassName = '',
        wrapperClassName = '',
        invalidFeedback,
        valid,
        value,
        defaultValue,
    } = props;

    const handleChange = (e: any) => {
        const value = e.target.value;
        if (onChange) {
            onChange(value);
        }
    };

    useEffect(() => {
        if (onChange) {
            onChange(defaultValue);
        }
    }, [defaultValue]);

    const inputClass = `block p-2 pl-4 w-full rounded appearance-none focus:outline-none bg-transparent ${inputClassName}`;
    let htmlInput: any = '';
    if (value != null) {
        htmlInput = (
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                className={inputClass}
            />
        );
        if (type == 'textarea') {
            htmlInput = (
                <textarea
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    value={value}
                    className={inputClass}
                />
            );
        }
    }
    else {
        htmlInput = (
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={handleChange}
                className={inputClass}
            />
        );
        if (type == 'textarea') {
            htmlInput = (
                <textarea
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    className={inputClass}
                />
            );
        }
    }

    const labelClass = mergeClasses('text-gray-500 absolute top-0 left-3 bg-mainBgColor p-2 duration-300 origin-0 pointer-events-none', labelClassName);
    const wrapperClass = mergeClasses('floating-label relative border focus-within:border-tertiary rounded', wrapperClassName);
    return (
        <div className="flex flex-col font-montserrat font-normal" style={{letterSpacing: 0.14}}>
            <div
                className={` ${wrapperClass}`}
            >
                {htmlInput}
                {label && (
                    <label
                        htmlFor={name}
                        className={`${labelClass}`}
                    >
                        {label}
                    </label>
                )}
            </div>
            {!valid && invalidFeedback ? (
                <span className="text-left pl-1 text-sm text-opacity-75 text-danger">
                    {invalidFeedback}
                </span>
            ) : (
                ''
            )}
        </div>
    );
};

export default Input;
