import Axios from 'axios';
import TCampaign from 'types/TCampaign';
import Http from './Http';
import {handleError} from './utils';

export interface IReport {
    key: string,
    value: string | number
}

export default class Campaign extends Http {
    constructor() {
        super('');
    }

    async sendReaction(campaignId: string, reactionFile: Blob, extension: string): Promise<void> {
        try {
            const formData = new FormData();
            formData.append('media', reactionFile, `reaction.${extension}`);
            await Axios.post(`/campaign/${campaignId}/reactions`, formData, this.requestConfig);
        }
        catch (error) {
            handleError(error);
        }
    }

    async sendResponse(campaignId: string, answer: boolean|null, deltaTime: number): Promise<void> {
        try {
            await Axios.patch(`/campaign/${campaignId}/reactions`, {'is_positive': answer, 'delta_time': deltaTime}, this.requestConfig);
        }
        catch (error) {
            handleError(error);
        }
    }

    async get(campaignId: string): Promise<TCampaign | undefined> {
        try {
            const ret = await Axios.get(`/campaigns/${campaignId}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }
}
