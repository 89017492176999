import { toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorException from '../../../services/exception/ErrorException';
import WarningException from '../../../services/exception/WarningException';
import SuccessException from '../../../services/exception/SuccessException';
import React from 'react';
import i18n from 'services/I18n';

class Notification {
    currentToast = Math.random();

    public dismiss() {
        toast.dismiss(this.currentToast);
    }

    public display(type: TypeOptions, text: string) {
        if (!toast.isActive(this.currentToast)) {
            this.currentToast = toast(text, {
                type: type,
                className: 'border-radius-5',
            });
        }
    }

    public displayException(err: ErrorException | WarningException | Error) {
        let type: TypeOptions;
        if (err instanceof ErrorException) {
            type = 'error';
        }
        else if (err instanceof WarningException) {
            type = 'warning';
        }
        else if (err instanceof SuccessException) {
            type = 'success';
        }
        else {
            type = 'error';
        }

        this.display(type, err.message);
    }

    public displayFormErrors(errors: Record<string, string>) {
        const html: any = [];
        Object.keys(errors).forEach((key: any) => {
            html.push(
                <li key={key}>
                    <span className="font-semibold">{i18n(key)}: </span>
                    <span>{i18n(errors[key])}</span>
                </li>
            );
        });
        this.currentToast = toast(
            <div>
                <span className="text-lg font-bold">{i18n('formHasErrors')} {Object.keys(errors).length ? ':' : '' }</span>
                <ul className="pl-2">
                    {html}
                </ul>
            </div>, {
                type: 'warning',
            }
        );
    }
}

export default new Notification();
export { ToastContainer as NotificationContainer } from 'react-toastify';
