import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpStorageI18n from './services/storage/Language';

import translationEn from './i18n/en/translations.json';
import translationEs from './i18n/es/translations.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {translation: translationEn},
        es: {translation: translationEs},
    },
    lng: HttpStorageI18n.getCurrentLanguage(),
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    ns: ['translations', 'countries'],
});

export default i18n;
