// GLOBAL CONSTANTS
// -----------------------------------

import i18n from 'services/I18n';

export const APP_RESOLUTIONS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
};

export const OBJECTIVES_COLORS: any = {
    attention: '35, 204, 255',
    validation: '253, 62, 107',
    engagement: '255, 173, 94',
    charisma: '222, 121, 215',
    surprise: '90, 213, 187',
    inspire: '85, 122, 255',
    intensity: '167, 90, 246',
    reject: '255, 134, 110',
    horror: '175, 198, 104',
    upset: '253, 121, 159',
    scared: '135, 140, 233',
};

export const SERVER_ERRORS = {
    userNotVerified: 'user_not_verified',
};

export const APP_MODALS = {
    modalForgotPassword: 'modalForgotPassword',
};

export const GENDER_SELECT = [
    {label: i18n('male'), value: 'male'},
    {label: i18n('female'), value: 'female'},
    {label: i18n('other'), value: 'other'},
];

const max = new Date().getFullYear() - 16;
const min = max - 100;
const years = [];
for (let i = max; i >= min; i--) {
    years.push({label: i.toString(), value: i});
}
export const YEAR_OPTIONS = years;

export const LOCATION_SELECT = [
    {label: i18n('city'), value: 'city'},
    {label: i18n('province'), value: 'province'},
    {label: i18n('region'), value: 'region'},
    {label: i18n('country'), value: 'country'},
];

export const APP_AVATAR_FILE_MAX_SIZE = 3072;
export const APP_WALLPAPER_FILE_MAX_SIZE = 5120;
export const APP_MEDIA_FILE_MAX_SIZE = 30720;
