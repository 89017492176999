import React, {useEffect, useState} from 'react';
import TCampaign from '../../types/TCampaign';
import CampaignService from '../../services/http/Campaign';
import AppPaths from '../../permissions/AppPaths';
import Button from '../../components/Common/Button/Button';
import {useTranslation} from 'react-i18next';

const Thanks = (props: any) => {
    const {t} = useTranslation();
    const campaignCode = props.match.params.code;
    const [campaign, setCampaign] = useState<Partial<TCampaign>>();

    useEffect(() => {
        const loadCampaign = async () => {
            try {
                setCampaign(
                    await new CampaignService().get(campaignCode)
                );
            }
            catch (e) {
                props.history.push(AppPaths.notFound);
            }
        };
        if (campaign === undefined) {
            loadCampaign();
        }
    }, [campaign]);

    return (
        <div className="h-screen flex items-center flex-col flex-row bg-dark-blue-grey-2 h-full">
            <div className="relative md:static w-full h-screen flex flex-col justify-center items-center">
                <span
                    style={{
                        fontSize: 46,
                        letterSpacing: -3.83,
                    }}
                    className="font-josefin font-semibold text-objective-validation text-center"
                >{t('thanksText')}</span>
                <span
                    style={{
                        opacity: 0.8,
                        lineHeight: 2,
                        fontSize: 16,
                        letterSpacing: -0.5,
                    }}
                    className="w-11/12 md:w-1/5 font-montserrat font-light text-white mt-5 text-center"
                >{campaign?.thanks}</span>
                {campaign?.offerLink &&
                    <Button type="primary" className="absolute mt-20 bottom-28 md:bottom-10 md:static" onClick={() => {
                        window.open(campaign?.offerLink);
                    }}>{t('continue')}</Button>
                }
            </div>
        </div>
    );
};

export default Thanks;
