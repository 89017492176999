/* eslint-disable */
import fp from "lodash/fp";
import _ from "lodash";
import moment from "moment";
import TCampaign from "types/TCampaign";
import COUNTRY_NAMES_AND_CODES from 'utils/countryNamesAndCodes.json';
import TAddress from "types/TAddress";

export function objsToNameList(
	identity: any,
	key: string,
	noItemsText: string
) {
	return !identity || !identity[key] || !identity[key].length
		? noItemsText
		: fp.pipe(
				fp.map((item: any) => item.name),
				fp.join(", ")
		  )(identity[key]);
}

export const toMoney = (number: any) => {
	return !isNaN(number)
		? new Intl.NumberFormat("de-DE", {
				style: "currency",
				currency: "EUR",
		  }).format(number)
		: "0,00 €";
};

export const uuidv4 = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
		/[xy]/g,
		function (c) {
			const r = (Math.random() * 16) | 0,
				v = c == "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		}
	);
};

export const byteSizeToHuman = (byteSize: number) => {
	return byteSize / 1024 < 1000
		? `${Math.floor(byteSize / 1024)} KB`
		: `${Math.floor(byteSize / 1024 / 1024)} MB`;
};

export const downloadFile = (url: string, fileName?: string) => {
	const anchor = document.createElement("a");
	anchor.href = url;
	anchor.target = "_blank";
	anchor.download = fileName || 'file';
	anchor.click();
};

export const objToQueryString = (obj: any) => {
	const qs = Object.keys(obj)
		.map((key: any) => {
			if (obj[key] !== "" && obj[key] !== null) {
				return `${key}=${obj[key]}`;
			} else {
				return "";
			}
		})
		.filter((item: any) => item !== "");

	return qs.join("&");
};

export const queryStringToObj = (qs: string) => {
	const urlParams = new URLSearchParams(qs);
	let params;
	if (Object.fromEntries) {
		params = Object.fromEntries(urlParams);
	} else {
		if (qs !== "") {
			let newObject: any = {};
			const splitQS = qs.split("&");
			splitQS.map((item: any) => {
				let dataArray: any[] = item.split("=");
				newObject[dataArray[0]] = dataArray[1];
			});
			params = newObject;
		}
	}

	for (const key in params) {
		const value = params[key];
		const values = value.split(",");
		if (values.length) {
			// Is array;
			params[key] = values;
		}
	}
	return params;
};

export const clearPathParams = () => {
	window.history.replaceState(null, "", window.location.pathname);
};

/**
 * Convertir array de values en un string;
 * @param values
 * @param key Si los values son objetos, la key del objeto;
 */
export const arrayToText = (values: any[], key?: string) => {
	let ret = "";

	if (values) {
		values.forEach((value, index) => {
			if (typeof value == "string") {
				ret += `${value}`;
			} else if (typeof value == "object" && key && value[key]) {
				ret += `${value[key]}`;
			}

			if (index < values.length - 1) {
				ret += ", ";
			}
		});
	}

	return ret;
};

export const numberInRange = (num: number, min: number, max: number) => {
	return num >= min && num <= max;
};

export function isOdd(num: number) {
	return num % 2;
}

export function isEven(num: number) {
	return !isOdd(num);
}

export function objectKeysToCamelCase(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map((v) => objectKeysToCamelCase(v));
	} else if (obj != null && obj.constructor === Object) {
		return Object.keys(obj).reduce(
			(result, key) => ({
				...result,
				[_.camelCase(key)]: objectKeysToCamelCase(obj[key]),
			}),
			{}
		);
	}
	return obj;
}

export function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

export function formatDateForInput(date: Date | string) {
	return moment(date).format('YYYY-MM-DD');
}

export function capitalize(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function campaignAddressToString(campaign: TCampaign) {
	const ret = campaign?.configuration ? Object.values(campaign?.configuration?.address).filter(Boolean).join(", ") : ''
	return ret; 
}

export function campaignAddressGetCurrent(address: any) {
	let ret: any = null; 
	if (address) {
		const keys = ["country", "region", "province", "city"]; 
		let i = 0; 
		while (i < keys.length && ret == null) {
			const k = keys[i];
			if (address[k] != null) {
				ret = {}; 
				ret[k] = address[k]; 
			}
			i++;
		}
	}
	return ret; 
}

export function findCountryCodeByName(countryName: string) {
	let ret = "";
	const countries: any = COUNTRY_NAMES_AND_CODES; 
	let i = 0; 
	while (ret == "" && i < countries.length) {
		const country = countries[i]; 
		if (country.name.toLowerCase() == countryName.toLowerCase()) {
			ret = country.code; 
		}
		i++;
	}
	return ret; 
}

export function arrayRemoveDuplicates(array: any[]) {
	return _.uniq(array);
}