import i18n from '../I18n';
import ErrorException from '../exception/ErrorException';
import _ from 'lodash';

interface IError {
    message: string;
    code: number;
    type: string;
}
export function handleError(err: any): IError {
    // console.log("HANDLE ERRO", err);
    let errorMessage;
    if (!err.response) {
        throw new Error(errorMessage);
    }
    switch (err.response.status) {
    case 400:
    case 500:
    case 401:
    case 403:
    case 404:
    case 409:
        errorMessage = i18n(`errors.${_.camelCase(err.response.data.errorType)}`) + `\n: ${err.response.data.message}`;
        break;
    default:
        errorMessage = i18n('errors.unknown');
    }

    throw new ErrorException(
        errorMessage,
        err.response.status,
        err.response.data.errorType
    );
}
