/* eslint-disable */
import React from 'react';
import RegexLibrary from 'utils/Regex';

interface IValidationObject {
    feedbackMessage?: string;
    validations: Array<
        | 'isEmail'
        | 'required'
        | 'password'
        | 'url'
        | 'urlHttps'
        | { sameAs: string }
        | { min: number | Date }
        | { max: number | Date }
    >;
    valid?: boolean ;
    value?: any;
}

interface IForm {
    [key: string]: IValidationObject;
}


function validateValue(field: IValidationObject, value: any, form: IForm): boolean {
    let ret = false;
    const check = field.validations;
    
    if (value) {
        let i = 0;
        while (i < check.length) {
            const validator = check[i];
            if (validator == 'isEmail') {
                ret = RegexLibrary.email.test(value);
            }
            if (validator == 'required') {
                if (typeof value == "string") {
                    ret = value.length > 0 && value != '' && value != null;
                }
                else if (typeof value == "number") {
                    ret = value != null; 
                }
            }
            if (validator == 'url') {
                ret = RegexLibrary.url.test(value);
            }
            if (validator == 'urlHttps') {
                ret = RegexLibrary.urlHttps.test(value);
            }
            if (typeof validator == "object") {
                if ("sameAs" in validator) {
                    const key = validator.sameAs; 
                    ret = value == form[key].value;
                }
                if ("min" in validator) {
                    if (typeof validator.min == "object") {
                        // console.log("dates not supported");
                        ret = true;
                    }
                    else if (typeof validator.min == "number") {
                        ret = Number(value) >= Number(validator.min);
                    }
                }
                if ("max" in validator) {
                    if (typeof validator.max == "object") {
                        // console.log("dates not supported");
                        ret = true;
                    }
                    else if (typeof validator.max == "number") {
                        ret = Number(value) >= Number(validator.max);
                    }
                }
            }
            i++;
        }

        if (check.length == 0) {
            ret = true;
        }
    }
    return ret;
}

export default function useFormValidations<T>(form: IForm): any {
    const [_form, _setForm] = React.useState(form);
  
    /**
     * On Change de un input;
     * @param key key
     * @param value value
     */
    const _onChange = (key: any, value: any) => {
        if (_form[key]) {
            const field = _form[key];
            field.value = value;
            if (field.validations.length) {
                const isValid = validateValue(field, value, _form);
                field.valid = isValid;
            }
            const n = { ..._form };
            n[key] = field;
            // Set value; Se hace set aunque el valor puede ser invalid;
            _setForm(n);
        }
    };

    /**
     * Handler de change de un input; Sirve para llamarlo desde el componente;
     * EJ:
     * <Input
            name="email"
            label="email"
            onChange={(v: any) => {onInputChange("email", v)}}
        ></Input>
     * @param key
     * @param value
     */
    const onInputChange = (key: string, value: any) => {
        _onChange(key, value);
    };

    /**
     * Valida todos los inputs del formulario;
     * @returns
     */
    const validateForm = (): boolean => {
        let ret = true;
        let i = 0;
        const keys = Object.keys(_form);
        while (i < keys.length && ret == true) {
            const key = keys[i];
            const field = _form[key];
            ret = validateValue(field, field.value, _form);
            field.valid = ret; 
            i++;
        }
        return ret;
    };

    /**
     * Get todos los fields que tienen un error;
     * @returns
     */
    const formErrors = () => {
        const ret: any = {};
        for (const key in _form) {
            if (!("valid" in _form[key]) || _form[key].valid == false) {
                ret[key] = _form[key]?.feedbackMessage || `input${key.charAt(0).toUpperCase() + key.slice(1)}Error`;
            }
        }
        return ret;
    };

    /**
     * Check si un field es valido;
     * @param key
     * @returns
     */
    const fieldIsValid = (key: string): boolean => {
        if (_form[key] && _form[key].value) {
            // @ts-ignore
            return validateValue(_form[key], _form[key].value, _form);
        }
        else {
            return true;
        }
    };

    const formIsValid = (): boolean => {
        return Object.keys(formErrors()).length == 0;
    };

    /**
     * Get value de un field
     * @param key
     */
    const getValue = (key: string): any => {
        let ret = null;
        if (_form[key]) {
            ret = _form[key].value;
        }
        return ret;
    };

    const getValues = () => {
        const ret: any = {};
        for (const key in _form) {
            ret[key] = getValue(key);
        }
        return ret;
    };

    return {
        onInputChange,
        validateForm,
        fieldIsValid,
        getValues,
        getValue,
        formIsValid,
        formErrors
    };
}
