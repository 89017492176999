import React from 'react';
import {useTranslation} from 'react-i18next';

const NotFound = (props: any) => {
    const {t} = useTranslation();
    return (
        <div className="flex items-center flex-col bg-objective-validation h-screen">
            <img
                src="https://uploads-ssl.webflow.com/5ec807c5d85901644b2db3b2/5f7ca90edb689fcff703aacc_oops.png"
                className="mt-20 h-16"/>
            <div
                className="mt-10 font-josefin text-white font-bold text-center opacity-50 text-8xl tracking-tighter">
                {t('oops')}
            </div>
            <div className="font-josefin text-white text-2xl">{t('notFoundTitle')}</div>
            <div className="font-josefin mt-20 px-5 text-center">
                {t('notFoundText')}
                <br/>
                {t('notFoundTextGoBack')}
            </div>
        </div>
    );
};

export default NotFound;
