import React, {useState, Fragment, useContext} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import ModalProvider from 'providers/modalProvider';
import Button from '../Button/Button';
import {useTranslation} from 'react-i18next';

interface IModalFooterProps extends IModalProps {
  closeModal(): void;
}
const ModalFooter = (props: IModalFooterProps) => {
    const {t} = useTranslation();
    const {closeModal, showBtnSubmit, onSubmit} = props;
    return (
        <div className="mt-4 border-t pt-3 flex flex-row justify-between items-center">
            <Button type="secondary" onClick={closeModal}>
                {t('close')}
            </Button>
            {showBtnSubmit ? (
                <Button type="primary" onClick={onSubmit}>
                    {t('accept')}
                </Button>
            ) : (
                ''
            )}
        </div>
    );
};

interface IModalProps {
  children?: any;
  onClose?(): void;
  title?: string;
  closeText?: string;
  showBtnSubmit?: boolean;
  onSubmit?(): void;
}
const Modal = (props: IModalProps) => {
    const {children, onClose, title} = props;
    const [isOpen, setIsOpen] = useState(true);
    // eslint-disable-next-line
    const [showModal, setShowModal] = useContext(ModalProvider);

    const closeModal = () => {
        if (onClose) {
            onClose();
        }
        setShowModal(false);
        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            {title && (
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 border-b pb-3"
                                >
                                    {title}
                                </Dialog.Title>
                            )}
                            <div className="mt-4">{children}</div>

                            <ModalFooter closeModal={closeModal} {...props} />
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Modal;
